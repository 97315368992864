











import { Component, Mixins, Prop } from 'vue-property-decorator'
import { Api } from '@/edshed-common/api/methods'
import ComponentHelper from '@/mixins/ComponentHelper'

@Component({
  name: 'D6ActivateButton'
})
export default class D6ActivateButton extends Mixins(ComponentHelper) {
  @Prop({ required: true }) d6Id!: number

  schoolData : any = null
  loading : boolean = false

  get isActive () {
    return this.schoolData && this.schoolData.activated_by_integrator === 'Yes'
  }

  mounted () {
    this.loading = true
    this.getD6SchoolData()
  }

  async getD6SchoolData () {
    const resp = await Api.getD6SchoolData(this.d6Id)
    if (resp.schools.length > 0) {
      this.schoolData = resp.schools[0]
    }
    // console.log('SCH DATA')
    // console.log(this.schoolData)
    this.loading = false
  }

  async activateSchool () {
    await Api.d6ActivateSchool(this.d6Id)
    this.getD6SchoolData()
  }

  async deActivateSchool () {
    await Api.d6DeactivateSchool(this.d6Id)
    this.getD6SchoolData()
  }

  async syncD6 () {
    this.loading = true
    try {
      await Api.d6Sync(this.d6Id)
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.toastError(err.message)
      }
    } finally {
      this.loading = false
    }
  }
}

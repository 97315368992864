












































































































































































































































































































































































































































































































































































import { Api, planCycleToMomentPeriod, SchoolModel, SubscriptionPlanCycleType, SubscriptionPlanInfo, SubscriptionPlanPriceType, SubscriptionProductInfo, SubscriptionProductScopeInfo, SubscriptionUpgradeCostResponse } from '@/edshed-common/api'
import { Currency, DomesticCurrency } from '@/edshed-common/api/types/currency'
import ComponentHelper from '@/mixins/ComponentHelper'
import { PaymentMethod } from '@stripe/stripe-js'
import moment from 'moment'
import { UnreachableCaseError } from 'ts-essentials'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import Stripe from 'stripe'
import CouponPicker from './CouponPicker.vue'
import PriceTiers from './PriceTiers.vue'
import StripeCardPayment, { StripeCardPaymentMeta } from './StripeCardPayment.vue'

@Component({
  name: 'AddFreeSubscription',
  components: { CouponPicker, PriceTiers, StripeCardPayment }
})
export default class AddFreeSubscription extends Mixins(ComponentHelper) {
  @Prop({ required: true }) school!: SchoolModel

  coupon: Stripe.Coupon | null = null

  expiry: Date | null = null

  quantity: number = 1

  postStripe: boolean = false

  sendInvoice: boolean = true

  paymentMethod: string | null = null

  activeStep: number = 0

  sendingSub: boolean = false

  setExpiry: boolean | null = null

  selectedCurrency: Currency | null = null

  domestic: boolean = false

  Currency = Currency

  DomesticCurrency = DomesticCurrency

  loadingPlans: boolean = false

  plans: SubscriptionPlanInfo[] = []

  baseProduct: SubscriptionProductInfo | null = null

  baseScope: SubscriptionProductScopeInfo | null = null

  selectedBasePlan: SubscriptionPlanInfo | null = null

  selectedAddOnProducts: SubscriptionProductInfo [] = []

  selectedAddOns: SubscriptionPlanInfo[] = []

  showCouponPicker: Boolean = false

  moment = moment

  type: 'free' | 'trial' | 'full' | null = null

  fullSubType: 'normal' | 'backdated' | 'future' | null = null

  backdatedSubType: 'pay-full' | 'pay-remaining' | null = null

  costs: SubscriptionUpgradeCostResponse | null = null

  response: string | null = null

  trialDays: number = 30

  trialDates: Date[] = [new Date(), new Date('2023-07-13')]

  trialPupils: number = 36

  trialTeachers: number = 6

  trialClasses: number = 3

  unlimitedTrialPupils: boolean = true

  unlimitedTrialTeachers: boolean = true

  unlimitedTrialClasses: boolean = true

  poNumber: string = ''

  planCycleToMomentPeriod = planCycleToMomentPeriod

  @Watch('activeStep')
  stepChanged (val: number, oldVal: number) {
    if (val === 2 && oldVal < val) {
      this.getPlans()
    }

    if (val === 3) {
      if (this.bundleOptionsForBasePlan.length === 0) {
        oldVal < 3 ? this.activeStep++ : this.activeStep--
      }
    }

    // navigating back to a step before base plan selection
    if (oldVal >= 2 && val < 2) {
      this.baseProduct = null
      this.baseScope = null
      this.selectedBasePlan = null
    }

    // navigating back to a step before add-on selection
    if (oldVal >= 3 && val < 3) {
      this.selectedAddOns = []
      this.selectedAddOnProducts = []
    }

    // navigate back from Type step
    if (oldVal >= 4 && val < 4) {
      this.type = null
      this.fullSubType = null
    }

    // return to Type step
    if (oldVal > 4 && val === 4) {
      if (this.fullSubType === 'backdated') {
        this.backdatedSubType = null
      } else if (this.fullSubType) {
        this.fullSubType = null
      } else {
        this.type = null
      }
    }

    // navigating back to a step before expiry selection
    if (oldVal >= 5 && val < 5) {
      this.setExpiry = null
      this.expiry = null
    }

    // navigating back to the expiry selection when
    if (oldVal >= 5 && val === 5 && this.setExpiry === false) {
      this.setExpiry = null
    }

    // navigated back from summary page
    if (oldVal >= 7 && val < 7) {
      this.paymentMethod = null
      this.costs = null
    }

    // arrive at summary page
    if (val === 7 && oldVal < 7) {
      if (this.postStripe) {
        this.loadSubscriptionCosts()
      }
    }
  }

  @Watch('baseProduct')
  productChanged () {
    this.$nextTick(() => {
      if (this.school.org_type) {
        const bestScope = this.scopesForSelectedBaseProduct.find(s => s.org_types.includes(this.school.org_type!))

        if (bestScope) {
          this.baseScope = bestScope
        }

        return
      }

      if (this.scopesForSelectedBaseProduct.length) {
        this.baseScope = this.scopesForSelectedBaseProduct[0]
      }
    })
  }

  @Watch('baseScope')
  scopeChanged () {
    this.$nextTick(() => {
      const activePlan = this.plansForScope.find(p => p.active)

      this.selectedBasePlan = activePlan ?? null

      if (!this.selectedBasePlan && this.plansForScope.length > 0) {
        this.selectedBasePlan = this.plansForScope[0]
      }
    })
  }

  @Watch('selectedBasePlan.min')
  minChanged (newVal: number | undefined) {
    if (newVal && this.quantity < newVal) {
      this.quantity = newVal
    }
  }

  @Watch('selectedBasePlan.max')
  maxChanged (newVal: number | undefined) {
    if (newVal && this.quantity > newVal) {
      this.quantity = newVal
    }
  }

  @Watch('selectedBasePlan.price_type')
  priceTypeChanged (newVal: SubscriptionPlanPriceType) {
    if (newVal === 'fixed') {
      this.quantity = 1
    }
  }

  @Watch('trialDays', { immediate: true })
  trialDaysChanged (newVal: number) {
    if (newVal < 1) {
      this.trialDays = 1
    }

    if (newVal > 90) {
      this.trialDays = 90
    }

    this.trialDates = [new Date(), moment().add(newVal, 'days').toDate()]
  }

  @Watch('trialDates')
  trialDatesChanged (newVal: Date[]) {
    const days = moment(newVal[1]).diff(moment().startOf('day'), 'days')
    this.trialDays = days
  }

  get hasDomesticOption () {
    if (!this.selectCurrency) {
      return false
    }

    return DomesticCurrency.find(c => c === this.selectedCurrency) !== undefined
  }

  get basePlans () {
    return this.plans.filter(p => p.parent_id === null)
  }

  get availableProducts () {
    const map: Map<number, SubscriptionProductInfo> = new Map()

    for (const plan of this.basePlans.filter(b => this.baseProduct === null || b.product_id === this.baseProduct.id)) {
      map.set(plan.product.id, plan.product)
    }

    return Array.from(map.values())
  }

  get plansForSelectedBaseProduct () {
    if (this.baseProduct === null) {
      return []
    }

    return this.basePlans.filter(p => p.product_id === this.baseProduct!.id)
  }

  get scopesForSelectedBaseProduct () {
    const map: Map<number, SubscriptionProductScopeInfo> = new Map()

    for (const plan of this.plansForSelectedBaseProduct) {
      map.set(plan.scope.id, plan.scope)
    }

    return Array.from(map.values())
  }

  get plansForScope () {
    if (this.baseScope === null) {
      return []
    }

    return this.basePlans.filter(p => p.scope_id === this.baseScope!.id)
  }

  get bundleOptionsForBasePlan () {
    if (!this.selectedBasePlan) {
      return []
    }

    return this.plans.filter(p => this.selectedBasePlan!.bundle_children.find(c => c.id === p.id))
  }

  get expiryDate () {
    if (!this.selectedBasePlan) {
      return ''
    }

    // @ts-ignore
    return new Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'short' }).format(moment().add(1, this.durationFromCycleType(this.selectedBasePlan.cycle)).toDate())
  }

  get isReadyToSubmit () {
    if (!this.selectedBasePlan) {
      return false
    }

    if (this.type === 'trial') {
      return true
    }

    if (this.setExpiry && !this.expiry) {
      return false
    }

    if (this.postStripe && !this.sendInvoice && !this.paymentMethod) {
      return false
    }

    return true
  }

  get requiresInput () {
    return this.selectedBasePlan?.metered_entity
  }

  get qtyLabel () {
    if (this.selectedBasePlan?.metered_entity === 'class') {
      return 'Classes'
    } else if (this.selectedBasePlan?.metered_entity === 'teacher') {
      return 'Teachers'
    } else if (this.selectedBasePlan?.metered_entity === 'pupil') {
      return 'Pupils'
    }

    return ''
  }

  get showPupilCount () {
    return this.selectedBasePlan?.pupils && this.selectedBasePlan?.metered_entity !== 'pupil'
  }

  get showTeacherCount () {
    return this.selectedBasePlan?.teachers && this.selectedBasePlan?.metered_entity !== 'teacher'
  }

  get showClassCount () {
    return this.selectedBasePlan?.classes && this.selectedBasePlan?.metered_entity !== 'class'
  }

  get paymentMeta (): StripeCardPaymentMeta | null {
    if (!this.selectedBasePlan || this.type === null) {
      return null
    }

    if (this.type === 'full') {
      if (this.fullSubType === 'normal') {
        return {
          type: 'superuser.subscription.full',
          add_on_plan_ids: this.selectedAddOns.map(a => a.id),
          base_plan_id: this.selectedBasePlan.id,
          quantity: this.quantity,
          school_id: this.school.id,
          purchase_order: this.poNumber.trim() !== '' ? this.poNumber : undefined
        }
      } else if (this.fullSubType === 'backdated') {
        if (this.backdatedSubType === null || this.expiry === null) {
          return null
        }

        return {
          type: 'superuser.subscription.backdated',
          add_on_plan_ids: this.selectedAddOns.map(a => a.id),
          base_plan_id: this.selectedBasePlan.id,
          quantity: this.quantity,
          school_id: this.school.id,
          charge_type: this.backdatedSubType,
          expiry_date: Math.floor(this.expiry.getTime() / 1000),
          purchase_order: this.poNumber.trim() !== '' ? this.poNumber : undefined
        }
      } else if (this.fullSubType === 'future') {
        return {
          type: 'superuser.manage.cards',
          school_id: this.school.id
        }
      } else {
        return null
      }
    } else if (this.type === 'free') {
      return {
        type: 'manage.cards'
      }
    } else if (this.type === 'trial') {
      return null
    }

    return null
  }

  close () {
    this.$emit('close')
  }

  selectCurrency (curr: Currency) {
    this.selectedCurrency = curr

    this.$nextTick(() => {
      if (this.hasDomesticOption) {
        this.activeStep = 1
      } else {
        this.activeStep = 2
      }
    })
  }

  setDomestic (val: boolean) {
    this.domestic = val

    this.activeStep = 2
  }

  selectBaseProduct (val: SubscriptionProductInfo) {
    this.baseProduct = val
  }

  unselectBaseProduct () {
    this.selectedBasePlan = null
    this.baseProduct = null
    this.baseScope = null
  }

  setType (type: 'free' | 'trial' | 'full') {
    this.type = type

    if (this.type === 'trial') {
      this.expiry = null
      this.setExpiry = true
      this.setPostStripe(false)
    } else if (this.type === 'full') {
      this.expiry = null
      this.postStripe = true
      this.fullSubType = null
    }
  }

  unselectType (type: 'free' | 'trial' | 'full') {
    this.type = null

    if (type === 'full') {
      this.fullSubType = null
    }
  }

  setFullType (type: 'normal' | 'backdated' | 'future') {
    this.fullSubType = type

    // no date choice
    if (type === 'normal') {
      this.activeStep = 6
    }

    // choose start date
    if (type === 'future') {
      this.setExpiry = true
      this.activeStep = 5
    }
  }

  setBackdatedType (type: 'pay-full' | 'pay-remaining') {
    this.backdatedSubType = type
    this.setExpiry = true
    this.activeStep = 5
  }

  setExpiryType (type: boolean) {
    this.setExpiry = type

    if (type === false) {
      this.expiry = null
      this.activeStep = 6
    }
  }

  setPostStripe (val: boolean) {
    this.postStripe = val

    this.activeStep = 5
  }

  setResponse (str: string) {
    this.response = str
  }

  resetResponse () {
    this.response = null
  }

  paymentCompleted () {
    this.$emit('success', true)
    this.$emit('close')
  }

  durationFromCycleType (cycle: SubscriptionPlanCycleType) {
    switch (cycle) {
      case 'monthly':
        return 'month'
      case 'yearly':
        return 'year'
      default:
        throw new UnreachableCaseError(cycle)
    }
  }

  toggleAddOn (plan: SubscriptionPlanInfo) {
    if (this.selectedAddOns.find(a => a.id === plan.id)) {
      this.selectedAddOns = this.selectedAddOns.filter(a => a.id !== plan.id)
    } else {
      this.selectedAddOns.push(plan)
    }

    this.$forceUpdate()
  }

  paymentMethodSelected (pm: PaymentMethod | null) {
    this.paymentMethod = pm?.id ?? null
  }

  addOnProductToggled (plan: SubscriptionPlanInfo) {
    const product = plan.product

    if (!this.selectedAddOnProducts.find(p => p.id === product.id)) {
      // added - add a default option
      const plansForProduct = this.plans.filter(p => p.scope_id === plan.scope_id && p.parent_id !== null)

      const bestOption = plansForProduct.find(p => p.active) ?? plansForProduct[0]

      if (bestOption) {
        this.selectedAddOns.push(bestOption)
      }

      this.selectedAddOnProducts.push(product)
    } else {
      // removed
      this.selectedAddOnProducts = this.selectedAddOnProducts.filter(p => p.id !== product.id)
      this.selectedAddOns = this.selectedAddOns.filter(a => a.product_id !== product.id)
    }
  }

  getSelectedAddOnForProduct (product: SubscriptionProductInfo) {
    return this.selectedAddOns.find(a => a.product_id === product.id)
  }

  setSelectedAddOnForProduct (product: SubscriptionProductInfo, plan: SubscriptionPlanInfo) {
    console.log('setting selected')
    this.selectedAddOns = this.selectedAddOns.filter(a => a.product_id !== product.id)

    this.selectedAddOns.push(plan)
  }

  async getPlans () {
    try {
      this.loadingPlans = true

      this.plans = await Api.getSubscriptionPlans({
        currency: this.selectedCurrency ?? undefined,
        domestic: this.domestic
      }, undefined)
    } catch (err: unknown) {
      this.$buefy.toast.open({
        message: 'Could not load plans',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.loadingPlans = false
    }
  }

  async submit () {
    try {
      if (!this.selectedBasePlan) {
        throw new Error('No base plan selected')
      }

      this.sendingSub = true

      if (this.type === 'free') {
        await Api.superuserAddFreeSubscription(this.school.id, {
          add_on_plan_ids: this.selectedAddOns.map(a => a.id),
          base_plan_id: this.selectedBasePlan.id,
          coupon: this.coupon ? this.coupon.id : null,
          expiry: this.setExpiry ? this.expiry : null,
          post_stripe: this.postStripe,
          send_invoice: this.sendInvoice,
          payment_method: this.paymentMethod,
          quantity: this.quantity
        })
      } else if (this.type === 'full') {
        if (this.fullSubType === 'normal') {
          await Api.superuserAddFullSubscription(this.school.id, {
            add_on_plan_ids: this.selectedAddOns.map(a => a.id),
            base_plan_id: this.selectedBasePlan.id,
            coupon: this.coupon ? this.coupon.id : null,
            payment_method: null,
            quantity: this.quantity,
            po_number: this.poNumber.trim() !== '' ? this.poNumber : undefined
          })
        } else if (this.fullSubType === 'backdated') {
          if (this.expiry === null || this.backdatedSubType === null) {
            throw new Error('Missing data')
          }

          await Api.superuserAddBackdatedSubscription(this.school.id, {
            add_on_plan_ids: this.selectedAddOns.map(a => a.id),
            base_plan_id: this.selectedBasePlan.id,
            coupon: this.coupon ? this.coupon.id : null,
            payment_method: null,
            quantity: this.quantity,
            charge_type: this.backdatedSubType,
            expiry_date: Math.floor(this.expiry.getTime() / 1000),
            po_number: this.poNumber.trim() !== '' ? this.poNumber : undefined
          })
        } else if (this.fullSubType === 'future') {
          if (this.expiry === null) {
            throw new Error('Missing data')
          }

          await Api.superuserAddFutureSubscription(this.school.id, {
            add_on_plan_ids: this.selectedAddOns.map(a => a.id),
            base_plan_id: this.selectedBasePlan.id,
            coupon: this.coupon ? this.coupon.id : null,
            payment_method: null,
            quantity: this.quantity,
            start_date: Math.floor(this.expiry.getTime() / 1000),
            po_number: this.poNumber.trim() !== '' ? this.poNumber : undefined
          })
        }
      } else if (this.type === 'trial') {
        await Api.superuserAddTrialSubscription(this.school.id, {
          add_on_plan_ids: this.selectedAddOns.map(a => a.id),
          base_plan_id: this.selectedBasePlan.id,
          classes: this.unlimitedTrialClasses ? null : this.trialClasses,
          pupil_seats: this.unlimitedTrialPupils ? null : this.trialPupils,
          teacher_seats: this.unlimitedTrialTeachers ? null : this.trialTeachers,
          days: this.trialDays
        })
      }

      this.$emit('success')
      this.$emit('close')
    } catch (err: unknown) {
      this.$buefy.toast.open({
        message: 'Could not add subscription',
        position: 'is-bottom',
        type: 'is-danger'
      })
    } finally {
      this.sendingSub = false
    }
  }

  async loadSubscriptionCosts () {
    try {
      if (!this.selectedBasePlan) {
        throw new Error('No base plan selected')
      }

      if (this.fullSubType === 'normal' || (this.type === 'free' && this.expiry === null)) {
        this.costs = await Api.getSchoolsSubscriptionCost(this.school.id, {
          base_plan_id: this.selectedBasePlan.id,
          add_on_plan_ids: this.selectedAddOns.map(a => a.id),
          quantity: this.quantity,
          type: 'normal'
        })
      } else if (this.fullSubType === 'future') {
        if (!this.expiry) {
          throw new Error('Expiry not set')
        }

        this.costs = await Api.getSchoolsSubscriptionCost(this.school.id, {
          base_plan_id: this.selectedBasePlan.id,
          add_on_plan_ids: this.selectedAddOns.map(a => a.id),
          quantity: this.quantity,
          type: this.fullSubType,
          date: Math.floor(moment(this.expiry).toDate().getTime() / 1000)
        })
      } else if (this.fullSubType === 'backdated' || (this.type === 'free' && this.expiry !== null)) {
        if (!this.expiry) {
          throw new Error('Expiry not set')
        }

        const chargeType = this.fullSubType === 'backdated' ? this.backdatedSubType : 'pay-remaining'

        if (!chargeType) {
          throw new Error('Charge type not set')
        }

        this.costs = await Api.getSchoolsSubscriptionCost(this.school.id, {
          base_plan_id: this.selectedBasePlan.id,
          add_on_plan_ids: this.selectedAddOns.map(a => a.id),
          quantity: this.quantity,
          type: 'backdated',
          date: Math.floor(moment(this.expiry).subtract(1, planCycleToMomentPeriod(this.selectedBasePlan.cycle)).toDate().getTime() / 1000),
          charge_type: chargeType
        })
      }
    } catch (err: unknown) {
      this.$buefy.toast.open({
        message: 'Could load subscription costs',
        position: 'is-bottom',
        type: 'is-danger'
      })
    }
  }

  setCoupon (coupon: Stripe.Coupon) {
    this.coupon = coupon
    this.showCouponPicker = false
  }
}














































































































































import { Api, StripeInvoiceInfo, StripeInvoicePaymentMethod, toISO8601Date } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import MarkPaidModal from '@/components/views/components/MarkPaidModal.vue'
import UploadRemittanceModal from '@/components/views/components/UploadRemittanceModal.vue'

@Component({
  name: 'InvoiceRow',
  components: {
    MarkPaidModal,
    UploadRemittanceModal
  }
})
export default class InvoiceRow extends Mixins(ComponentHelper) {
  @Prop({ required: true }) invoice!: StripeInvoiceInfo

  showRemittance: boolean = false

  showMarkPaid: boolean = false

  purchaseOrderNumber: string = ''

  mounted () {
    this.purchaseOrderNumber = this.invoice.po_number
  }

  async sendToXero () {
    try {
      await Api.sendInvoiceToXero(this.invoice.id)

      this.$emit('sent-xero', this.invoice)
    } catch (err) {
      if (err instanceof Error) {
        this.$buefy.toast.open(`${err.message}`)
      } else {
        console.log(err)
      }
    }
  }

  async setForex () {
    const p = prompt('Enter GBP value. Note, this must be final.')

    if (p) {
      try {
        await Api.setInvoiceFinalAmount(this.invoice.id, parseFloat(p))

        this.$emit('set-final-amount', this.invoice)
      } catch (err) {
        if (err instanceof Error) {
          this.$buefy.toast.open(`${err.message}`)
        } else {
          console.log(err)
        }
      }
    }
  }

  async markInvoicePromised () {
    const c = confirm('Mark this invoice as promised?')

    if (c) {
      const p = prompt('Reference?')

      try {
        await Api.markInvoicePromised(this.invoice.invoice_id, p)

        this.$emit('invoice-promised', this.invoice)
      } catch (err) {
        if (err instanceof Error) {
          this.$buefy.toast.open(`${err.message}`)
        } else {
          console.log(err)
        }
      }
    }
  }

  async markInvoicePaid (invoiceId: string, method: StripeInvoicePaymentMethod, date: Date) {
    this.showMarkPaid = false

    const c = confirm('Mark this invoice as paid?')

    if (c) {
      try {
        await Api.markInvoicePaid(invoiceId, method, toISO8601Date(date))

        this.$emit('marked-paid', this.invoice)
      } catch (err) {
        if (err instanceof Error) {
          this.$buefy.toast.open(`${err.message}`)
        } else {
          console.log(err)
        }
      }
    }
  }

  async markInvoiceLocallyVoid () {
    const c = confirm('This invoice will be marked as void in our hub. This will not void the invoice in Xero or Stripe. The invoice will no longer count towards sales totals.')

    if (c) {
      try {
        const newInvoice = await Api.markInvoiceLocallyVoid(this.invoice.id)

        this.$emit('marked-locally-void', newInvoice)
      } catch (err) {
        if (err instanceof Error) {
          this.$buefy.toast.open(`${err.message}`)
        } else {
          console.log(err)
        }
      }
    }
  }

  async savePurchaseOrderNumber () {
    try {
      await Api.updateInvoice(this.invoice.id, this.purchaseOrderNumber)

      this.$buefy.toast.open({
        message: 'Updated',
        type: 'is-success',
        position: 'is-bottom'
      })

      this.$emit('po-updated', this.purchaseOrderNumber)
    } catch (err) {
      if (err instanceof Error) {
        this.$buefy.toast.open(`${err.message}`)
      } else {
        console.log(err)
      }
    }
  }

  downloadRemittance () {
    window.location.href = 'https://files.edshed.' + (process.env.NODE_ENV === 'production' ? 'com' : 'xyz') + '/' + this.invoice.remittance_url
  }
}







































































import { Component, Mixins, Prop } from 'vue-property-decorator'
import Modal from '../components/Modal.vue'
import WondeSettings from './WondeSettings.vue'
import { Api } from '@/edshed-common/api/methods'
import ComponentHelperBase from '@/edshed-common/mixins/ComponentHelperBase'

@Component({
  components: { WondeSettings, Modal },
  name: 'SyncWithWondeButton'
})
export default class SyncWithWondeButton extends Mixins(ComponentHelperBase) {
  @Prop({ default: null }) wondeId!: string | null
  @Prop({ default: false }) wondeIsApproved!: boolean

  wondeIdToSync: string | null = null
  mWondeSyncing: boolean = false
  syncNoteIsSticky: boolean = false

  showWondeSettings: boolean = false

  saveNeeded: boolean = false

  mounted () {
    this.wondeIdToSync = this.wondeId || this.$store.state.user?.school?.wonde_id

    this.checkIfWondeIsSyncing()
  }

  saveClicked () {
    (this.$refs.wondeSettings as WondeSettings).presaveCheck()
  }

  syncClicked () {
    (this.$refs.wondeSettings as WondeSettings).syncNowClicked()
  }

  set wondeSyncing (flag: boolean) {
    this.mWondeSyncing = flag

    if (this.mWondeSyncing === true) {
      setTimeout(() => {
        this.checkIfWondeIsSyncing()
      }, 10 * 1000)
    } else {
      this.syncNoteIsSticky = false
    }
  }

  get wondeSyncing () {
    return this.mWondeSyncing
  }

  get wondeLogoUrl () {
    return '../images/logos/wonde-small.png'
  }

  async checkIfWondeIsSyncing () {
    if (!this.wondeIdToSync) {
      this.mWondeSyncing = false
      return false
    }

    this.wondeSyncing = await Api.checkWondeSyncFlag(this.wondeIdToSync)
  }

  wondeSyncStarted () {
    this.wondeSyncing = true
    this.showWondeSettings = false
    this.syncNoteIsSticky = true

    setTimeout(() => {
      this.syncNoteIsSticky = false
    }, 5000)
  }

  async doWondeSync () {
    if (!this.wondeIdToSync) {
      throw new Error('No id to sync')
    }

    await Api.wondeSync(this.wondeIdToSync)

    this.$buefy.toast.open({
      message: 'Wonde sync started',
      type: 'is-success'
    })

    this.wondeSyncStarted()
  }
}



































































































































































































































































































































import { Api, isPersonal, SchoolModel, StripeInvoiceInfo, Subscription, SubscriptionPlanInfo, SubscriptionProductType, toISO8601Date } from '@/edshed-common/api'
import ComponentHelper from '@/mixins/ComponentHelper'
import moment from 'moment'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import StripeInvoices from '@/components/views/components/StripeInvoices.vue'
import EditSubscriptionPermissions from './EditSubscriptionPermissions.vue'
import InvoiceRow from './InvoiceRow.vue'
import AddManualInvoice from './AddManualInvoice.vue'

@Component({
  name: 'SubscriptionCard',
  components: {
    EditSubscriptionPermissions,
    InvoiceRow,
    StripeInvoices,
    AddManualInvoice
  }
})
export default class SubscriptionCard extends Mixins(ComponentHelper) {
  @Prop({ required: true }) sub!: Subscription

  @Prop({ required: true }) school!: SchoolModel[]

  invoices: StripeInvoiceInfo[] = []

  editingSubPermissions: Subscription | null = null

  stripeInvoicesModal: boolean = false

  previewRenewalModal: boolean = false

  previewRenewalContent: string | null = null

  purchaseOrderNumber: string = ''

  showManualInvoiceModal: boolean = false

  canSaveManualInvoice: boolean = false

  mounted () {
    if (this.sub.subscription_id !== null) {
      this.loadInvoices()
    }

    this.purchaseOrderNumber = this.sub.po_number
  }

  shouldShowProductTag (basePlan: SubscriptionPlanInfo, addOnPlans: SubscriptionPlanInfo[], product: SubscriptionProductType) {
    return basePlan.product.product_type === product || addOnPlans.find(a => a.product.product_type === product)
  }

  shouldShowPersonalTag (plan: SubscriptionPlanInfo) {
    return isPersonal(plan)
  }

  editPupilSeatsForSub () {
    const sub = this.sub
    this.$buefy.dialog.prompt({
      message: 'Enter new value for total pupil seats',
      inputAttrs: {
        type: 'number',
        placeholder: 'Enter total pupil seats',
        value: sub.pupil_seats
      },
      trapFocus: true,
      onConfirm: value => this.updatePupilSeats(parseInt(value, 10))
    })
  }

  isExpired (d: Subscription['expiry']) {
    return moment(d) < moment()
  }

  openStripeInvoicesModal () {
    this.stripeInvoicesModal = true
  }

  closeStripeInvoicesModal () {
    this.stripeInvoicesModal = false
  }

  async openPreviewRenewalModal () {
    this.previewRenewalContent = await Api.getRenewalEmail(this.sub.id)
    this.previewRenewalModal = true
  }

  closePreviewRenewalModal () {
    this.previewRenewalContent = null
    this.previewRenewalModal = false
  }

  openManualInvoiceModal () {
    this.showManualInvoiceModal = true
  }

  saveInvoice () {
    (this.$refs.addInvoice as AddManualInvoice).createInvoice()
  }

  addManuallyCreatedInvoice (inv: StripeInvoiceInfo) {
    this.invoices.push(inv)
    this.showManualInvoiceModal = false

    this.$buefy.toast.open({
      message: 'Invoice created',
      position: 'is-bottom',
      type: 'is-success'
    })
  }

  editTeacherSeatsForSub () {
    const sub = this.sub

    this.$buefy.dialog.prompt({
      message: 'Enter new value for total teacher seats',
      inputAttrs: {
        type: 'number',
        placeholder: 'Enter total teacher seats',
        value: sub.teacher_seats
      },
      trapFocus: true,
      onConfirm: value => this.updateTeacherSeats(sub, parseInt(value, 10))
    })
  }

  async inferredTeachersToggled (val: boolean) {
    try {
      await Api.updateTeachersInferredAccess(this.sub.id, val)
      this.$emit('inferred-access-changed', { subId: this.sub.id, val })
    } catch (err) {
      if (err instanceof Error) {
        this.$buefy.toast.open(`${err.message}`)
      } else {
        console.log(err)
      }
    }
  }

  async loadInvoices () {
    try {
      if (this.sub.subscription_id === null) {
        return
      }

      this.invoices = await Api.superuserGetInvoicesForSubscription(this.sub.subscription_id)
    } catch (err) {
      if (err instanceof Error) {
        this.$buefy.toast.open(`${err.message}`)
      } else {
        console.log(err)
      }
    }
  }

  async updatePupilSeats (seats: number) {
    const sub = this.sub

    try {
      await Api.updatePupilSeats(sub.id, seats)
      sub.pupil_seats = seats
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.$buefy.toast.open(`${err.message}`)
      } else {
        console.log(err)
      }
    }

    this.$forceUpdate()
  }

  async updateTeacherSeats (sub, seats) {
    try {
      await Api.updateTeacherSeats(sub.id, seats)
      sub.teacher_seats = seats
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.$buefy.toast.open(`${err.message}`)
      } else {
        console.log(err)
      }
    }

    this.$forceUpdate()
  }

  async changeExpiry () {
    const p = prompt('Change Expiry (YYYY-MM-DD HH:mm:ss)', moment(this.sub.expiry).format('YYYY-MM-DD HH:mm:ss'))

    const parsed = moment(p, 'YYYY-MM-DD HH:mm:ss', true)

    if (parsed.isValid()) {
      try {
        const isoDate = toISO8601Date(parsed.toDate())
        await Api.superuserSetExpiry(this.sub.id, isoDate)

        this.$emit('expiry-updated', { subId: this.sub.id, expiry: isoDate })
      } catch (err: unknown) {
        if (err instanceof Error) {
          this.$buefy.toast.open(`${err.message}`)
        } else {
          console.log(err)
        }
      }
    }
  }

  async syncSubscription () {
    try {
      await Api.syncSubscription(this.sub.id)
    } catch (err: unknown) {
      if (err instanceof Error) {
        this.$buefy.toast.open(`${err.message}`)
      } else {
        console.log(err)
      }
    }
  }

  async saveSubscriptionPermissions () {
    try {
      if (!this.editingSubPermissions) {
        throw new Error('No subscription to edit')
      }

      await Api.updateSubscriptionPermissions(this.editingSubPermissions.id, this.editingSubPermissions.permissions)

      this.$buefy.toast.open({
        duration: 5000,
        message: 'Permissions Updated',
        type: 'is-success'
      })

      this.editingSubPermissions = null
    } catch (err) {
      if (err instanceof Error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: err.message || err.toString(),
          type: 'is-warning'
        })
      } else {
        console.log(err)
      }
    }
  }

  async moveSubscription () {
    const sub = this.sub

    if (sub.subscription_id) {
      alert('Not allowed')
    }

    const newSchool = prompt('Move to school ID?', sub.school_id.toString())

    if (newSchool && parseInt(newSchool) !== sub.school_id) {
      try {
        await Api.moveSubscription(sub.id, parseInt(newSchool))

        this.$emit('moved-sub', sub)
      } catch (err) {
        if (err instanceof Error) {
          this.$buefy.toast.open(`${err.message}`)
        } else {
          console.log(err)
        }
      }
    }
  }

  async deleteSubscription () {
    const sub = this.sub

    if (sub.subscription_id) {
      alert('Not allowed')
    }

    const c = confirm('Delete subscription? This cannot be undone.')
    if (c) {
      try {
        await Api.deleteSubscription(sub.id)
        this.$emit('sub-deleted', sub)
      } catch (err) {
        if (err instanceof Error) {
          this.$buefy.toast.open(`${err.message}`)
        } else {
          console.log(err)
        }
      }
    }
  }

  async cancelAtExpiry () {
    if (this.sub.subscription_id === null) {
      throw new Error('Not a Stripe subscription')
    }

    const c = confirm('Are you sure you want to cancel this subscription?')

    if (c) {
      try {
        await Api.cancelSubscriptionAtExpiry(this.sub.subscription_id)

        this.$emit('cancel-at-expiry', this.sub)
      } catch (err) {
        if (err instanceof Error) {
          this.$buefy.toast.open(`${err.message}`)
        } else {
          console.log(err)
        }
      }
    }
  }

  async uncancel () {
    if (!this.sub.subscription_id) {
      return
    }

    const c = confirm('Are you sure you want to reactivate this subscription?')
    if (c) {
      try {
        await Api.uncancelSubscriptionAtExpiry(this.sub.subscription_id)

        this.$emit('uncancel-at-expiry', this.sub)
      } catch (err) {
        if (err instanceof Error) {
          this.$buefy.toast.open(`${err.message}`)
        } else {
          console.log(err)
        }
      }
    }
  }

  async makeDisabled () {
    const c = confirm('Mark this subscription as inactive?')

    if (c) {
      try {
        await Api.deactivateSubscription(this.sub.id)

        this.$emit('sub-deactivated', this.sub)
      } catch (err) {
        if (err instanceof Error) {
          this.$buefy.toast.open(`${err.message}`)
        } else {
          console.log(err)
        }
      }
    }
  }

  async cancelRefund () {
    if (!this.sub.subscription_id) {
      return
    }

    const c = confirm('Are you sure you want to cancel and refund this subscription? This cannot be undone.')

    if (c) {
      try {
        await Api.cancelSubscriptionAndRefund(this.sub.subscription_id)

        this.$emit('cancel-and-refund', this.sub)
      } catch (err) {
        if (err instanceof Error) {
          this.$buefy.toast.open(`${err.message}`)
        } else {
          console.log(err)
        }
      }
    }
  }

  async cancelVoid () {
    if (!this.sub.subscription_id) {
      return
    }

    const c = confirm('Are you sure you want to cancel this subscription? This cannot be undone.')

    if (c) {
      try {
        await Api.cancelSubscriptionAndMarkVoid(this.sub.subscription_id)

        this.$emit('cancel-and-void', this.sub)
      } catch (err) {
        if (err instanceof Error) {
          this.$buefy.toast.open(`${err.message}`)
        } else {
          console.log(err)
        }
      }
    }
  }

  async cancelUncollectable () {
    if (!this.sub.subscription_id) {
      return
    }

    const c = confirm('Are you sure you want to cancel this subscription? This cannot be undone.')

    if (c) {
      try {
        await Api.cancelSubscriptionAndMarkUncollectable(this.sub.subscription_id)

        this.$emit('cancel-and-uncollectable')
      } catch (err) {
        if (err instanceof Error) {
          this.$buefy.toast.open(`${err.message}`)
        } else {
          console.log(err)
        }
      }
    }
  }

  async savePurchaseOrderNumber () {
    try {
      await Api.updateSubscription(this.sub.id, this.purchaseOrderNumber)

      this.$buefy.toast.open({
        message: 'Updated',
        type: 'is-success',
        position: 'is-bottom'
      })

      this.$emit('po-updated', this.purchaseOrderNumber)
    } catch (err) {
      if (err instanceof Error) {
        this.$buefy.toast.open(`${err.message}`)
      } else {
        console.log(err)
      }
    }
  }
}




































































































































































































































































































































































































































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { Api, RawWondeClass, RawWondeGroup, RawWondePupil, RawWondeStaff, WondeExclusionInfo, WondeExclusionToAdd, WondeExclusionType, WondePresyncInfo } from '../api'
import ComponentHelperBase from '../mixins/ComponentHelperBase'

@Component
export default class WondeSettings extends Mixins(ComponentHelperBase) {
  @Prop({ required: true }) wondeId!: string

  wondeDetails: WondePresyncInfo = {
    groups: [],
    classes: [],
    staff: [],
    pupils: [],
    problems: {
      upn: []
    },
    invalid_staff: []
  }

  view: 'groups' | 'classes' | 'staff' | 'pupils' = 'groups'

  checkedGroupRows: RawWondeGroup[] = []
  checkedClassRows: RawWondeClass[] = []
  checkedStaffRows: RawWondeStaff[] = []
  checkedPupilRows: RawWondePupil[] = []

  checkedPupilsWithNoGroups: RawWondePupil[] = []
  checkedStaffWithNoGroups: RawWondeStaff[] = []

  loadingMessage: string | null = null

  exclusions: WondeExclusionInfo[] = []

  currentPupilPage: number = 1
  currentUpnErrorIndex: number = 1
  currentPupilWarningIndex: number = 0

  checkStaffModal: boolean = false
  checkPupilModal: boolean = false
  checkExclusionsModal: boolean = false

  mode: 'simple' | 'advanced' = 'simple'

  @Watch('view')
  onViewChange (val: 'groups' | 'classes' | 'staff' | 'pupils') {
    if (val === 'staff') {
      this.updateCheckedStaffWithNoGroups()
    } else if (val === 'pupils') {
      this.updateCheckedPupilsWithNoGroups()
    }
  }

  async unselectAllPupils () {
    this.checkedPupilRows = []
    await this.updateCheckedPupilsWithNoGroups()
    this.checkedPupilsWithNoGroups = []
  }

  async selectAllPupils () {
    this.checkedPupilRows = this.wondeDetails.pupils
    await this.updateCheckedPupilsWithNoGroups()
    this.checkedPupilsWithNoGroups = [...this.checkedPupilsWithNoGroups]
  }

  async unselectAllStaff () {
    this.checkedStaffRows = []
    await this.updateCheckedStaffWithNoGroups()
    this.checkedStaffWithNoGroups = []
  }

  async selectAllStaff () {
    this.checkedStaffRows = this.wondeDetails.staff
    await this.updateCheckedStaffWithNoGroups()
    this.checkedStaffWithNoGroups = [...this.checkedStaffWithNoGroups]
  }

  pupilsTableChecked () {
    this.updateCheckedPupilsWithNoGroups()
  }

  staffTableChecked () {
    this.updateCheckedStaffWithNoGroups()
  }

  groupsTableChecked (data: RawWondeGroup[], row: RawWondeGroup | undefined) {
    const that = this
    function handleUncheck () {
      that.updateCheckedPupilsWithNoGroups()
      that.$nextTick(() => {
        that.excludePupilsWithNoGroupsClicked()
      })

      that.updateCheckedStaffWithNoGroups()
      that.$nextTick(() => {
        that.excludeTeachersWithNoGroupsClicked()
      })
    }

    function handleCheck (group: RawWondeGroup) {
      that.includePupilsInGroup(group)
      that.includeTeachersInGroup(group)
    }

    if (this.mode === 'simple') {
      if (row === undefined) {
        if (data.length > this.checkedGroupRows.length) {
          // added page
          const addedRows = data.filter(r => this.checkedGroupRows.find(g => g.code === r.code) === undefined)

          for (const row of addedRows) {
            handleCheck(row)
          }
        } else {
          // removed page
          handleUncheck()
        }
      } else if (data.find(g => g.code === row.code) !== undefined) {
        // added single
        handleCheck(row)
      } else {
        // removed single
        handleUncheck()
      }
    }
  }

  selectAllGroupsClicked () {
    this.checkedGroupRows = this.wondeDetails.groups

    if (this.mode === 'simple') {
      this.includePupilsInAllGroups()
      this.includeTeachersInAllGroups()
    }
  }

  unselectAllGroupsClicked () {
    this.checkedGroupRows = []

    if (this.mode === 'simple') {
      this.updateCheckedPupilsWithNoGroups()
      this.checkedPupilRows = this.checkedPupilsWithNoGroups

      this.updateCheckedStaffWithNoGroups()
      this.checkedStaffRows = this.checkedStaffWithNoGroups
    }
  }

  classesTableChecked (data: RawWondeClass[], row: RawWondeClass | undefined) {
    const that = this
    function handleUncheck () {
      that.updateCheckedPupilsWithNoGroups()
      that.$nextTick(() => {
        that.excludePupilsWithNoGroupsClicked()
      })

      that.updateCheckedStaffWithNoGroups()
      that.$nextTick(() => {
        that.excludeTeachersWithNoGroupsClicked()
      })
    }

    function handleCheck (_class: RawWondeClass) {
      that.includePupilsInClass(_class)
      that.includeTeachersInClass(_class)
    }

    if (this.mode === 'simple') {
      if (row === undefined) {
        if (data.length > this.checkedClassRows.length) {
          // added page
          const addedRows = data.filter(r => this.checkedClassRows.find(c => c.code === r.code) === undefined)

          for (const row of addedRows) {
            handleCheck(row)
          }
        } else {
          // removed page
          handleUncheck()
        }
      } else if (data.find(g => g.code === row.code) !== undefined) {
        // added single
        handleCheck(row)
      } else {
        // removed single
        handleUncheck()
      }
    }
  }

  selectAllClassesClicked () {
    this.checkedClassRows = this.wondeDetails.classes

    if (this.mode === 'simple') {
      this.includePupilsInAllGroups()
      this.includeTeachersInAllGroups()
    }
  }

  unselectAllClassesClicked () {
    this.checkedClassRows = []

    if (this.mode === 'simple') {
      this.updateCheckedPupilsWithNoGroups()
      this.checkedPupilRows = this.checkedPupilsWithNoGroups

      this.updateCheckedStaffWithNoGroups()
      this.checkedStaffRows = this.checkedStaffWithNoGroups
    }
  }

  selectedPupilCountForGroup (code: string) {
    return this.checkedPupilRows.filter(p => p.groups.data.find(g => g.code === code) !== undefined).length
  }

  pupilCountForGroup (code: string) {
    return this.wondeDetails.pupils.filter(p => p.groups.data.find(g => g.code === code) !== undefined).length
  }

  selectedTeacherCountForGroup (code: string) {
    return this.checkedStaffRows.filter(p => p.groups.data.find(g => g.code === code) !== undefined).length
  }

  teacherCountForGroup (code: string) {
    return this.wondeDetails.staff.filter(p => p.groups.data.find(g => g.code === code) !== undefined).length
  }

  selectedPupilCountForClass (code: string) {
    return this.checkedPupilRows.filter(p => p.classes.data.find(c => c.code === code) !== undefined).length
  }

  pupilCountForClass (code: string) {
    return this.wondeDetails.pupils.filter(p => p.classes.data.find(c => c.code === code) !== undefined).length
  }

  selectedTeacherCountForClass (code: string) {
    return this.checkedStaffRows.filter(p => p.classes.data.find(c => c.code === code) !== undefined).length
  }

  teacherCountForClass (code: string) {
    return this.wondeDetails.staff.filter(p => p.classes.data.find(c => c.code === code) !== undefined).length
  }

  excludePupilsWithNoGroupsClicked () {
    this.checkedPupilRows = this.checkedPupilRows.filter(p => !this.checkedPupilsWithNoGroups.find(p2 => p2.upi === p.upi))
    this.updateCheckedPupilsWithNoGroups()
  }

  excludeTeachersWithNoGroupsClicked () {
    this.checkedStaffRows = this.checkedStaffRows.filter(p => !this.checkedStaffWithNoGroups.find(p2 => p2.upi === p.upi))
    this.updateCheckedStaffWithNoGroups()
  }

  includePupilsInGroup (group: RawWondeGroup) {
    const pupils = this.wondeDetails.pupils.filter(p => p.groups.data.find(g => g.code === group.code) !== undefined)

    for (const pupil of pupils) {
      if (this.checkedPupilRows.find(p => p.upi === pupil.upi) === undefined) {
        this.checkedPupilRows.push(pupil)
      }
    }
  }

  includePupilsInAllGroups () {
    this.checkedPupilRows = this.wondeDetails.pupils.filter(p => p.groups.data.find(g => this.checkedGroupRows.find(g2 => g2.id === g.id)) || p.classes.data.find(c => this.checkedClassRows.find(c2 => c2.id === c.id)))
  }

  includeTeachersInGroup (group: RawWondeGroup) {
    const teachers = this.wondeDetails.staff.filter(p => p.groups.data.find(g => g.code === group.code) !== undefined)

    for (const teacher of teachers) {
      if (this.checkedStaffRows.find(p => p.upi === teacher.upi) === undefined) {
        this.checkedStaffRows.push(teacher)
      }
    }
  }

  includeTeachersInAllGroups () {
    this.checkedStaffRows = this.wondeDetails.staff.filter(s => s.groups.data.find(g => this.checkedGroupRows.find(g2 => g2.id === g.id)) || s.classes.data.find(s => this.checkedClassRows.find(c2 => c2.id === s.id)))
  }

  includePupilsInClass (_class: RawWondeClass) {
    const pupils = this.wondeDetails.pupils.filter(p => p.classes.data.find(g => g.code === _class.code) !== undefined)

    for (const pupil of pupils) {
      if (this.checkedPupilRows.find(p => p.upi === pupil.upi) === undefined) {
        this.checkedPupilRows.push(pupil)
      }
    }
  }

  includeTeachersInClass (_class: RawWondeClass) {
    const teachers = this.wondeDetails.staff.filter(p => p.classes.data.find(g => g.code === _class.code) !== undefined)

    for (const teacher of teachers) {
      if (this.checkedStaffRows.find(p => p.upi === teacher.upi) === undefined) {
        this.checkedStaffRows.push(teacher)
      }
    }
  }

  get excludedGroups () {
    return this.exclusions.filter(e => e.type === 'group')
  }

  get excludedClasses () {
    return this.exclusions.filter(e => e.type === 'class')
  }

  get excludedPupils () {
    return this.exclusions.filter(e => e.type === 'pupil')
  }

  get notExcludedPupils () {
    return this.wondeDetails.pupils.filter(p => !this.excludedPupils.find(p2 => p2.wonde_id === p.upi))
  }

  get notExcludedStaff () {
    return this.wondeDetails.staff.filter(s => !this.excludedStaff.find(s2 => s2.wonde_id === s.upi))
  }

  get excludedStaff () {
    return this.exclusions.filter(e => e.type === 'staff')
  }

  get addedGroupExclusions () {
    return this.wondeDetails.groups.filter(g => !this.excludedGroups.find(e => e.wonde_id === g.id) && !this.checkedGroupRows.find(ch => ch.id === g.id))
  }

  get addedClassExclusions () {
    return this.wondeDetails.classes.filter(c => !this.excludedClasses.find(e => e.wonde_id === c.id) && !this.checkedClassRows.find(ch => ch.id === c.id))
  }

  get addedPupilExclusions () {
    return this.notExcludedPupils.filter(p => !this.checkedPupilRows.find(ch => ch.upi === p.upi))
  }

  get addedStaffExclusions () {
    return this.notExcludedStaff.filter(s => !this.checkedStaffRows.find(ch => ch.upi === s.upi))
  }

  get removedGroupExclusions () {
    return this.wondeDetails.groups.filter(g => this.excludedGroups.find(e => e.wonde_id === g.id) && this.checkedGroupRows.find(ch => ch.id === g.id))
  }

  get removedClassExclusions () {
    return this.wondeDetails.classes.filter(c => this.excludedClasses.find(e => e.wonde_id === c.id) && this.checkedClassRows.find(ch => ch.id === c.id))
  }

  get removedPupilExclusions () {
    return this.excludedPupils.filter(p => this.checkedPupilRows.find(ch => ch.upi === p.wonde_id))
  }

  get removedStaffExclusions () {
    return this.excludedStaff.filter(s => this.checkedStaffRows.find(ch => ch.upi === s.wonde_id))
  }

  updateCheckedPupilsWithNoGroups () {
    this.$nextTick(() => {
      this.checkedPupilsWithNoGroups = this.checkedPupilRows.filter(p => !p.groups.data.find(g => this.checkedGroupRows.find(g2 => g2.id === g.id)) && !p.classes.data.find(c => this.checkedClassRows.find(c2 => c2.id === c.id)))
    })
  }

  updateCheckedStaffWithNoGroups () {
    this.$nextTick(() => {
      this.checkedStaffWithNoGroups = this.checkedStaffRows.filter(s => !s.groups.data.find(g => this.checkedGroupRows.find(g2 => g2.id === g.id)) && !s.classes.data.find(c => this.checkedClassRows.find(c2 => c2.id === c.id)))
    })
  }

  get saveNeeded () {
    return this.addedGroupExclusions.length > 0 || this.addedClassExclusions.length > 0 || this.addedPupilExclusions.length > 0 || this.addedStaffExclusions.length > 0 ||
    this.removedGroupExclusions.length > 0 || this.removedClassExclusions.length > 0 || this.removedPupilExclusions.length > 0 || this.removedStaffExclusions.length > 0
  }

  @Watch('saveNeeded')
  onSaveNeededChanged (val: boolean) {
    this.$emit('save-needed', val)
  }

  async mounted () {
    if (!this.wondeId) {
      return
    }

    try {
      this.loadingMessage = 'Loading your Wonde data. This may take a while.'
      this.wondeDetails = await Api.wondePreSync(this.wondeId)
      this.wondeDetails = {
        ...this.wondeDetails,
        staff: this.wondeDetails.staff.map(s => ({ ...s, all_groups: s.groups.data.map(g => g.name).join(', '), email: s.contact_details.data.emails.email, full_name: `${s.forename} ${s.surname}` })),
        pupils: this.wondeDetails.pupils.map(p => ({ ...p, all_groups: p.groups.data.map(g => g.name).join(', '), full_name: `${p.forename} ${p.surname}` }))
      }
      this.exclusions = await Api.getWondeExclusions(this.wondeId)

      this.$nextTick(() => {
        this.checkedGroupRows = this.wondeDetails.groups.filter(g => !this.excludedGroups.find(e => e.wonde_id === g.id))
        this.checkedClassRows = this.wondeDetails.classes.filter(c => !this.excludedClasses.find(e => e.wonde_id === c.id))
        this.checkedPupilRows = this.wondeDetails.pupils.filter(p => !this.excludedPupils.find(e => e.wonde_id === p.upi))
        this.checkedStaffRows = this.wondeDetails.staff.filter(s => !this.excludedStaff.find(e => e.wonde_id === s.upi))
      })
    } finally {
      this.loadingMessage = null
    }
  }

  async presaveCheck (pupilsOkay: boolean = false, staffOkay: boolean = false) {
    // update non-reactive data
    await this.updateCheckedPupilsWithNoGroups()
    await this.updateCheckedStaffWithNoGroups()

    if (this.checkedPupilsWithNoGroups.length > 0 && !pupilsOkay) {
      this.checkPupilModal = true
      return
    }

    if (this.checkedStaffWithNoGroups.length > 0 && !staffOkay) {
      this.checkStaffModal = true
      return
    }

    const allExclusionsToAdd: WondeExclusionToAdd[] = [
      ...this.addedGroupExclusions.map(g => ({ type: 'group' as WondeExclusionType, wonde_id: g.id })),
      ...this.addedClassExclusions.map(c => ({ type: 'class' as WondeExclusionType, wonde_id: c.id })),
      ...this.addedPupilExclusions.map(p => ({ type: 'pupil' as WondeExclusionType, wonde_id: p.upi })),
      ...this.addedStaffExclusions.map(s => ({ type: 'staff' as WondeExclusionType, wonde_id: s.upi }))
    ]

    if (allExclusionsToAdd.length > 0) {
      this.checkExclusionsModal = true
    } else {
      this.saveExclusions(false)
    }
  }

  async saveExclusions (deleteExclusions: boolean) {
    if (!this.saveNeeded || !this.wondeId) {
      return
    }

    try {
      this.loadingMessage = 'Saving your exclusion list, please wait.'
      const allExclusionsToAdd: WondeExclusionToAdd[] = [
        ...this.addedGroupExclusions.map(g => ({ type: 'group' as WondeExclusionType, wonde_id: g.id })),
        ...this.addedClassExclusions.map(c => ({ type: 'class' as WondeExclusionType, wonde_id: c.id })),
        ...this.addedPupilExclusions.map(p => ({ type: 'pupil' as WondeExclusionType, wonde_id: p.upi })),
        ...this.addedStaffExclusions.map(s => ({ type: 'staff' as WondeExclusionType, wonde_id: s.upi }))]

      const allExclusionsToRemove = [
        ...this.removedGroupExclusions.map(r => this.excludedGroups.find(e => e.wonde_id === r.id)!.id),
        ...this.removedClassExclusions.map(r => this.excludedClasses.find(e => e.wonde_id === r.id)!.id),
        ...this.removedPupilExclusions.map(r => r.id),
        ...this.removedStaffExclusions.map(r => r.id)
      ]

      if (allExclusionsToAdd.length > 0) {
        try {
          this.exclusions = await Api.addWondeExclusions(this.wondeId, allExclusionsToAdd, deleteExclusions)
        } catch (err: unknown) {
          if (err instanceof Error) {
            this.$buefy.toast.open({
              duration: 5000,
              message: err.message,
              position: 'is-bottom',
              type: 'is-danger'
            })
          }
        }
      }

      if (allExclusionsToRemove.length > 0) {
        this.exclusions = await Api.removeWondeExclusions(this.wondeId, allExclusionsToRemove)

        this.$buefy.dialog.confirm({
          title: 'Data Added',
          message: `You have opted to receive additional data. This information won\'t appear until you manually ${this.$t('synchronise')}. Would you like to do this now?`,
          cancelText: 'Later',
          confirmText: 'Sync Now',
          type: 'is-success',
          onConfirm: () => this.syncNowClicked()
        })
      }
    } finally {
      this.loadingMessage = null
    }
  }

  async syncNowClicked () {
    if (this.saveNeeded || !this.wondeId) {
      return
    }

    try {
      this.loadingMessage = 'Syncing Wonde data, please wait.'
      await Api.wondeSync(this.wondeId)
      this.$buefy.toast.open({
        message: 'Wonde sync started',
        type: 'is-success'
      })

      this.$emit('wonde-syncing')
    } finally {
      this.loadingMessage = null
    }
  }

  showPupilWithUPN (upn: string) {
    const index = this.wondeDetails.pupils.findIndex(p => p.education_details.data.upn === upn)

    this.view = 'pupils'
    this.currentPupilPage = Math.floor(index / 10) + 1
  }
}


































































import { Api } from '@/edshed-common/api'
import { Component, Mixins } from 'vue-property-decorator'
import ComponentHelper from '@/mixins/ComponentHelper'
import Stripe from 'stripe'

@Component({
  name: 'CouponPicker',
  props: {
    showCouponPicker: {
      type: Boolean,
      default: false,
      required: true
    }
  }
})

export default class AddSubscriptionPlan extends Mixins(ComponentHelper) {
  coupons: Stripe.Coupon[] = []
  showCouponPicker = this.$props.showCouponPicker

  mounted () {
    this.getCoupons()
  }

  close () {
    this.$emit('close')
  }

  async getCoupons () {
    try {
      this.coupons = await Api.getCoupons()
      this.coupons = this.coupons.filter(c => c.valid)
    } catch (err) {
      this.toastError('Could not retrieve coupon list')
    }
  }

  setCoupon (coupon: Stripe.Coupon) {
    this.$emit('setCoupon', coupon)
  }
}
